import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { ModalComponent } from '../components/modal/modal.component';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private sessionStorage: SessionStorageService,
    public modal: ModalComponent,
    private cookieService: CookieService
  ) { }

  getUser(){
    const url = environment.user_domain
    const tokenCookie = this.cookieService.get('sf-icp-sso') ? this.cookieService.get('sf-icp-sso') : 1;
    this.sessionStorage.setSessionData('auth-token', tokenCookie)
    let httpHeader = new HttpHeaders()
    httpHeader = httpHeader.append('Authorization', String(tokenCookie))
    return this.http.get(url, {headers: httpHeader}).subscribe({
      next:  (data) => {
      },
      error: (error) => {
        this.modal.handleModal(error.status)
      }
    })
  }



}
