<div class="-oneX-container">
    <div class="-oneX-row -oneX-justify-content-start -oneX-justify-content-start">
        <h5 tabindex="0" id="lossDet"><i>Contractor Comments</i></h5>
    </div>
    <form [formGroup]="commentForm" (ngSubmit)="onSubmit()" class="headerDivs">
        <div class="-oneX-row">
            <div class="-oneX-col-md-12 " [ngClass]="{'-oneX-widget--err':invalidComment}">
                <label for="commentBox" class="-oneX-textfield__label">Enter information or questions regarding the
                    claim and
                    State Farm will be in contact with you within 3 business days. </label>
                <textarea id="commentBox" class="-oneX-textfield--conversational-input" formControlName="comment"
                    aria-invalid="false" [(ngModel)]="remainingChar"></textarea>
                <div class="-oneX-textarea-charRemaining">{{ charLimit - remainingChar.length || 0 }}/{{charLimit}}
                    characters remaining</div>
                <div id="name-err" class="-oneX-widget__err-text" aria-live="assertive" *ngIf="invalidComment">
                    {{commentErrorMsg}}</div>
                <div class="-oneX-textarea-sensitiveText">To protect sensitive personal information, do not include full
                    SSN, TIN, SIN, DL#, financial account numbers, credit/debit card numbers, PHI or any medical
                    information
                </div>
            </div>
        </div>
        <br>
        <div class="-oneX-row" style="justify-self:center" *ngIf="isSuccess">
            <div class="-oneX-notification">
                <section class="-oneX-notification__icon -oneX-notification--confirmation">
                   <span class="-oneX-icon--confirmation" aria-label="confirmation" role='img'></span>  
                </section>
                <section class="-oneX-notification__text">
                    Comment successfully submitted
                </section>
              </div>
        </div>
        <div class="-oneX-row -oneX-justify-content-center" style="justify-self:center" *ngIf="isFail">
            <div class="-oneX-notification">
                <section class="-oneX-notification__icon -oneX-notification--error">
                    <span class="-oneX-icon--error" aria-label="error" role='img'></span>  
                </section>
                <section class="-oneX-notification__text">
                    Something has gone wrong, we apologize for the inconvenience. Please try again later or contact the
                    claim handler.
                </section>
            </div>
        </div>
        <br>
        <div class="-oneX-row -oneX-justify-content-center">
            <button tabindex="0" id="commentBtn" class="-oneX-btn-primary -oneX-btn-fit-content"
                type="submit">Submit</button>
        </div>
    </form>
</div>