import { Component, OnInit } from '@angular/core';
import { SearchComponent } from '../../components/search/search.component';
import { ErrorComponent } from '../../components/error/error.component';
import { DataAnalyticsService } from '../../services/data-analytics.service';

@Component({
  selector: 'app-search-page',
  standalone: true,
  imports: [SearchComponent, ErrorComponent],
  templateUrl: './search-page.component.html',
  styleUrl: './search-page.component.css'
})
export class SearchPageComponent implements OnInit{

  constructor(private dataTag: DataAnalyticsService) {}

  ngOnInit(): void {
    this.dataTag.getAnalyticsTagging('claim-view-search')
  }

}
