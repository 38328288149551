import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { formatDate } from '@angular/common';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimsApiService {

  constructor(private http: HttpClient, private sessionStorage: SessionStorageService) { }
  
  getClaim(claimNumber: string, dol: string, name: string): Observable<HttpResponse<Observable<any>>> {
    const url = environment.api_domain + claimNumber;
    dol = dol ? formatDate(dol.trim(), 'yyyy-MM-dd', 'en-US') : null;
    name = name ? name.trim() :null;
    const tokenCookie = this.sessionStorage.getSessionData('auth-token')
    let httpHeader = new HttpHeaders()
    httpHeader = httpHeader.append('Authorization', String(tokenCookie))
    const params = new HttpParams()
      .set('dol', dol)
      .set('name', name)
    return this.http.get<Observable<any>>(
      url, { params, headers: httpHeader , observe: 'response'})
  }
  
  postComment(claimNumber, body) {
    const url = environment.api_domain + claimNumber + '/comments'
    const tokenCookie = this.sessionStorage.getSessionData('auth-token')
    let httpHeader = new HttpHeaders()
    httpHeader = httpHeader.append('Authorization', String(tokenCookie))
    return this.http.post(url, JSON.stringify(body), {headers: httpHeader})
  }

}
