import { Component, ElementRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DataAnalyticsService } from '../../services/data-analytics.service';

@Component({
  selector: 'app-modal',
  standalone: true,
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.css'
})
export class ModalComponent {
secureLogin = environment.secureLogin
contactUs = environment.contactUs
  constructor (private element: ElementRef, private dataTag: DataAnalyticsService) {}
  handleModal(errorCode) {
    this.dataTag.getAnalyticsTagging('claim-view-technical-error')
    if (errorCode == 401 ){
      window['oneX'].Modal.showModal(this.element.nativeElement, 'unAuthModalContainer')
    } else if (errorCode = 403){
      window['oneX'].Modal.showModal(this.element.nativeElement, 'forbiddenModalContainer')
    }
  }
}
