<header data-type="1x-header-unauth" data-limited="true" data-hide-login="true"></header>
<div class="-oneX-row" style="justify-content: center;">
  <app-loader-spinner style="float:left"></app-loader-spinner>
</div>
<app-modal></app-modal>

<div id="container" class="-oneX"  data-component="appcomp">
  <router-outlet></router-outlet>
  </div>
<div class="content">
  <!-- <app-claim-found></app-claim-found> -->
  </div>
<footer data-type="1x-footer" data-limited="true"></footer>
