import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  checkForSessionData(key) {
    if (sessionStorage.getItem(key)) {
      return true;
    } else {
      console.warn(`No session data with the key ${key}`);
      return false;
    }
  }

  getSessionData(key) {
    if (this.checkForSessionData(key)) {
        const data = sessionStorage.getItem(key);
        try {
          JSON.parse(data);
          return JSON.parse(data);
        } catch {
          return data;
        }
    }
  }

  setSessionData(key, data) {
    if (typeof data === 'string') {
      sessionStorage.setItem(key, data);
    } else {
      sessionStorage.setItem(key, JSON.stringify(data));
    }
  }
}
