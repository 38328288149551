<div class="-oneX-container">
  <div class="-oneX-row -oneX-justify-content-start -oneX-justify-content-start">
    <h5 tabindex="0" id="paymentDetails"><i>Payment Details</i></h5>
  </div>
      <div id="pagination1">
          <div class="">
            <table class="-oneX-table--standard">
              <caption class="-oneX-tcaption"></caption>
              <thead class ="-oneX-thead">
              <tr class ="-oneX-tr">
                  <th class="-th--col -oneX-header">Payment Number</th>
                  <th class="-th--col -oneX-header">Date Issued</th>
                  <th class="-th--col -oneX-header">Paid To</th>
                  <th class="-th--col -oneX-header">Amount</th>
                  <th class="-th--col -oneX-header">Type</th>
                  <th class="-th--col -oneX-header">Status</th>
              </tr>
              </thead>
              <tbody class="-oneX-tbody"  *ngIf="!error">
              <tr *ngFor="let payment of currentPayments" class = "-oneX-tr">
                  <td class="-oneX-td -oneX-details">{{ payment.payment_number }}</td>
                  <td class="-oneX-td -oneX-details">{{ payment.payment_date }}</td>
                  <td class="-oneX-td -oneX-details">{{ payment.payee }}</td>
                  <td class="-oneX-td -oneX-details">{{ payment.amount | currency:'USD'}}</td>
                  <td class="-oneX-td -oneX-details">{{ payment.type }}</td>
                  <td class="-oneX-td -oneX-details">{{ payment.status }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="!error" [attr.data-number-of-items]="paymentsSize" data-current-items-per-page="10" aria-label="Pagination" data-pagination="indexer">
            <nav class="-oneX-pagination-pages" role="navigation">
              <div data-page="range"></div>
              <ul>
                <li>
                  <a data-chevron="prev" role="link" class="-oneX-pagination--prev--disabled" aria-disabled="true" aria-label="previous"></a>
                </li>
                <li>
                  <a class="-oneX-pagination--active" data-page="start"></a>
                </li>
                <li data-ellipsis='left' class='-oneX-hidden'> 
                  <span>...</span> 
                </li>
                <li>
                  <a data-page="small_list-index2"></a>
                </li>
                <li>
                  <a data-page="small_list-index3"></a>
                </li>
                <li>
                  <a data-page="small_list-index4"></a>
                </li>
                <li>
                  <a data-page="small_list-index5"></a>
                </li>
                <li>
                  <a data-page="small_list-index6"></a>
                </li>
                <li>
                  <a data-page="index1"></a>
                </li>
                <li>
                  <a data-page="index2"></a>
                </li>
                <li>
                  <a data-page="index3"></a>
                </li>
                <li data-ellipsis='right' class='-oneX-hidden'>
                  <span>...</span> 
                </li>
                <li>
                  <a data-page="end"></a>
                </li>
                <li>
                  <a data-chevron="next" role="link" class="-oneX-pagination--next--disabled" aria-disabled="true" aria-label="next"></a>
                </li>
              </ul>
            </nav>
          </div>
        <!-- </div> -->
      </div>
  </div>
