import { Injectable } from '@angular/core';
import { WindowRef } from '../models/window-ref';

@Injectable({
  providedIn: 'root'
})
export class DataAnalyticsService {

  constructor(private winRef: WindowRef) { }

  getAnalyticsTagging(screenId: string) {
    if (typeof this.winRef.nativeWindow.dataLayer === "undefined") {
      this.winRef.nativeWindow.dataLayer = {screenId: "", screenChannel: ""};
      this.getDataLayer(screenId);
    } else {
      this.getDataLayer(screenId);
    }
    const evt = this.createEvent("screenChange",
      typeof (Event) === 'function', {
        "bubbles": true,
        "cancelable": false
      });
    this.winRef.nativeWindow.dispatchEvent(evt);
  }

  getDataLayer(screenId) {
    this.winRef.nativeWindow.dataLayer.screenChannel = "b2b-fire-vendor";
    this.winRef.nativeWindow.dataLayer.screenId = screenId;
  }

  addPageData(layer: string, data: string) {
    window['dataLayer'][layer] = data;
  }

  private createEvent(eventName, eventConstructorExists, options) {
    if (eventConstructorExists) {
      return new Event(eventName, options);
    } else {
      // fallback since IE browsers don't support the Event constructor
      const evt = document.createEvent('Event');
      evt. initEvent("screenChange", options.bubbles, options.cancelable);
      return evt;
    }

  }
}
