<div class="-oneX-container">
    <div class="-oneX-row -oneX-justify-content-start -oneX-justify-content-start">
        <h5 tabindex="0" id="fileUp"><i>Document Upload</i></h5>
    </div>
    <label class="-oneX-textfield__label">Upload important documents and photos to the claim. Uploaded files become a permanent part of the claim file. </label>
    <br>
    <div class="-oneX-row -oneX-justify-content-center">
        <a tabindex="0" id="fileBtn" class="-oneX-btn-primary__anchor" target="_blank"
        role="button" href="{{fsptUrl}}" >Upload</a>
    </div>
</div>