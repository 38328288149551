import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Payload } from '../../classes/payload.model';
import { CommonModule } from '@angular/common';
import { ClaimsApiService } from '../../services/Claim/claims-api.service';
import { ModalComponent } from '../modal/modal.component';
import { DataAnalyticsService } from '../../services/data-analytics.service';

@Component({
  selector: 'app-comment',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './comment.component.html',
  styleUrl: './comment.component.css'
})
export class CommentComponent implements OnInit {
  remainingChar = '';
  charLimit: number = 500;
  
  @Input() claimNumber='';
  @Input() participant='';
  @Input() comment='';
  @Input() extId='';

  invalidComment=false;
  commentErrorMsg: string;

  isSuccess=false;
  isFail=false;

  commentForm = new FormGroup({
    claimNumber: new FormControl(this.claimNumber, { validators: [Validators.required]}),
    participant: new FormControl(this.participant, {validators: [Validators.required]}),
    comment: new FormControl(this.comment, {validators: [Validators.required]}),
    extId: new FormControl(this.extId, {validators: [Validators.required]})
  })

  constructor(private payload: Payload, private claimsApi: ClaimsApiService, public modal: ModalComponent,private dataTag: DataAnalyticsService
    ){}

  ngOnInit(): void {
    document.getElementById('commentBox').setAttribute("maxlength", this.charLimit.toString())
    this.commentForm.controls.claimNumber.setValue(this.payload.getClaimNumber())
    this.commentForm.controls.participant.setValue(this.payload.getUserName())
    this.commentForm.controls.extId.setValue(this.payload.getExtId())

  }

  errorInCommentBox(){
    if (this.commentForm.controls.comment.errors?.['required']) {
      this.invalidComment = true;
      this.commentErrorMsg = "Comment box cannot be empty before submitting"
      document.getElementById('commentBox').setAttribute('aria-invalid', 'true')
    }else {
      this.invalidComment = false;
      document.getElementById('commentBox').setAttribute('aria-invalid', 'false')
    }

  }

  onSubmit(){
    if (this.commentForm.invalid){
      this.errorInCommentBox();
    }
    if (!this.commentForm.invalid){
      const claimNumber = this.commentForm.controls.claimNumber.value;
      const body = {
        "participant_name" : this.commentForm.controls.participant.value,
        "comment": this.commentForm.controls.comment.value,
        "ext_claim_id": this.commentForm.controls.extId.value
      };
      this.postSubmit(claimNumber, body)
    }
  }

  postSubmit(claimNumber, body){
    this.claimsApi.postComment(claimNumber, body).subscribe({
      next: (data) => {
        this.commentForm.controls.comment.reset();
        this.remainingChar = '';
        this.isSuccess = true;
      }, 
      error: (error) => {
        this.dataTag.getAnalyticsTagging('claim-view-technical-error')
        if (error.status == 401 || error.status == 403){
          this.modal.handleModal(error.status)
        } else {   
          this.isFail = true; 
        }
      }
    })
  }
}
